import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Block from './primitives/Block'
import Box from './primitives/Box'
import Row from './primitives/Row'
import Button from './system/Button'

export const query = graphql`
  {
    cover: file(relativePath: { eq: "images/book-cta.png" }) {
      childImageSharp {
        gatsbyImageData(width: 436, height: 439, layout: FULL_WIDTH)
      }
    }
  }
`

export function BookCTA() {
  const data = useStaticQuery(query)
  const cover = data.cover.childImageSharp.gatsbyImageData

  return (
    <Box backgroundColor="book">
      <Block>
        <Row
          space={32}
          paddingTop={16}
          paddingBottom={48}
          alignItems="center"
          justifyContent="center"
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Box width={510} maxWidth="100%" flexShrink={0}>
            <Box font="h3" color="white" paddingBottom={32}>
              Read or buy our book
            </Box>
            <Box font="large" color="white" paddingBottom={16}>
              Build: Elements of an Effective Software Organization is a
              blueprint for engineering leaders who want to improve their
              business outcomes, developer productivity, and developer
              experience.
            </Box>
            <Button
              href="/build/"
              variant="plain"
              arrow
              fontWeight={500}
              color="white"
            >
              Learn more
            </Button>
          </Box>
          <Box width={436} maxWidth="100%">
            <GatsbyImage image={cover} alt="Build book cover" />
          </Box>
        </Row>
      </Block>
    </Box>
  )
}
